markCurrentNavLink();
addHamburgerButtonClickEvent();
addCurrentYearToFooter();

function markCurrentNavLink() {
    const currentUrl = window.location.href;
    const currentUrlElements = currentUrl.split("/");
    const currentPageUrlElement = currentUrlElements[currentUrlElements.length - 1];
    const navLinks = document.querySelectorAll(".nav-list > li > a");

    for (const navLink of navLinks) {
        const navLinkElements = navLink.href.split("/");
        const navLinklastElement = navLinkElements[navLinkElements.length - 1];
    
        if (currentPageUrlElement === navLinklastElement) {
            navLink.classList.add("current-page");
        }
    }
}

function addHamburgerButtonClickEvent() {
    const headerNav = document.querySelector(".header-nav");
    const hamburgerButton = document.querySelector(".hamburger-button");
    
    hamburgerButton.onclick = function() {
        headerNav.classList.toggle("display-block");
    }
}

function addCurrentYearToFooter() {
    const footerDate = document.querySelector("footer .date");
    footerDate.innerHTML = new Date().getFullYear();
}